import styled from 'styled-components';

import { fonts, palette } from '@/theme';

import desktop from './desktop.svg';
import mobile from './mobile.svg';

export const StyledSelect = styled.select`
  width: 100%;
  height: 60px;
  ${fonts.text}
  padding: 0 72px 0 12px;
  background: url(${desktop}) 100% 50% no-repeat;
  @media (hover) {
    &:hover {
      box-shadow: inset 0 0 0 3px ${palette.black};
    }
  }
  @media (max-width: 991px) {
    height: 30px;
    ${fonts.smallText22}
    padding: 0 38px 0 8px;
    background: url(${mobile}) 100% 50% no-repeat;
  }
  @media (max-width: 991px) and (hover) {
    &:hover {
      box-shadow: inset 0 0 0 2px ${palette.black};
    }
  }
`;

export const StyledOption = styled.option`
  ${fonts.text}
  padding: 0 12px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    padding: 0 8px;
  }
`;
