import styled from 'styled-components';

import { fonts, palette } from '@/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;

export const FormWrap = styled.div`
  position: absolute;
  top: 0;
  left: -62px;
  width: calc(100% + 2 * 62px);
  @media (max-width: 991px) {
    position: relative;
    left: -16px;
    width: calc(100% + 2 * 16px);
  }
`;

export const ContentWrap = styled.div`
  border-bottom: 2px solid ${palette.black};
  padding-left: calc((100% - 2 * 62px) / 3 + 62px + 2px);
  padding-right: 62px;
  @media (max-width: 991px) {
    border-width: 1px;
    padding: 0 16px;
  }
`;

export const Description = styled.p`
  ${({ isHidden }) => isHidden && 'display: none;'}
  ${fonts.smallText13}
  padding-top: 5px;
  padding-left: calc((100% - 2 * 62px) / 3 + 62px + 7px);
  padding-right: 62px;
  @media (max-width: 991px) {
    padding: 3px 20px;
  }
`;

export const Message = styled.p`
  ${fonts.smallText22}
  color: ${palette.red};
  padding: 4px;
`;
