import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { omit, propEq, path, pathEq } from 'ramda';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import { useAsyncState, useObserver } from '@/hooks';
import {
  KIND,
  MEMBERSHIP,
  PUBLISH,
  PAYMENT,
  AMOUNT,
  CURRENCY,
  PAYMENT_METHOD,
  INVOICE,
  COMPANY,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  ADDRESS,
  ZIP_CODE,
  CITY,
  EMAIL,
} from '@/server-api/consts';
import { useCreateDonator } from '@/server-api/hooks';
import Membership from '../Membership';
import Checkbox from '../Checkbox';
import Switcher from '../Switcher';

import { validationSchema } from './validationSchema';
import Select from './Select';
import { Container, FormWrap, ContentWrap, Description, Message } from './styles';

const Step2 = ({ pageContext, location }) => {
  const { kinds, benefits, payments } = pageContext;
  const formRef = useRef();
  const switchRef = useRef();
  const [values, setValues] = useState(omit(['key'], location.state));
  const [validateErr, setValidateErr] = useAsyncState();
  const [{ loading, success, error }, createDonator, reset] = useCreateDonator();
  const { setPosition } = useObserver();

  const {
    key,
    name,
    priceRange,
    currency,
    values: priceValues,
  } = useMemo(() => {
    const kind = kinds[values[KIND]];

    if (!(values[MEMBERSHIP] && kind?.memberships)) return {};

    return kind.memberships.find(propEq('key', values[MEMBERSHIP]));
  }, [kinds, values]);
  const selectList = useMemo(
    () => priceValues && priceValues.map((id) => ({ id, label: `${currency} ${id / 100}.00 –` })),
    [currency, priceValues]
  );

  const setPrice = useCallback(
    ({ target }) => {
      setValues(($) => ({
        ...$,
        [PAYMENT]: {
          ...$[PAYMENT],
          [AMOUNT]: Number(target.value),
          [CURRENCY]: currency,
        },
      }));
      setPosition(switchRef);
    },
    [currency, setPosition]
  );
  const handleBack = useCallback(() => navigate('/goenner/schritt-1/', { state: values }), [values]);
  const onSubmit = useCallback(async () => {
    if (error) reset();
    if (validateErr) setValidateErr();

    try {
      await validationSchema.validate(values, { abortEarly: false });
      createDonator((values[KIND] === 'private' && { ...omit([COMPANY], values), [TITLE]: values[COMPANY] || '' }) || values);
    } catch (e) {
      setValidateErr('Bitte überprüfen Sie schrittweise alle Felder der Formulare.');
    }
  }, [createDonator, error, reset, setValidateErr, validateErr, values]);

  useEffect(() => {
    if (!key) navigate('/goenner/');
  }, [key]);
  useEffect(() => {
    setPosition(formRef);
  }, [setPosition]);
  useEffect(() => {
    if (success) navigate('/goenner/danke/');
  }, [success]);

  if (!key) return null;

  return (
    <>
      <Container>
        <FormWrap>
          <ContentWrap ref={formRef}>
            <Select value={path([PAYMENT, AMOUNT], values)} values={selectList} onChange={setPrice} />
            <Checkbox
              type={`${PAYMENT_METHOD}-${INVOICE}`}
              selected={pathEq([PAYMENT, PAYMENT_METHOD], INVOICE, values)}
              label={path([INVOICE, 'name'], payments)}
            />
          </ContentWrap>
          <Description>{path([INVOICE, 'description'], payments)}</Description>
        </FormWrap>
        <Membership type={key} name={name} price={priceRange} benefits={benefits[key]} selected />
      </Container>
      <Switcher ref={switchRef} onPrev={handleBack} onNext={onSubmit} step={2} disabled={loading} />
      {(error || validateErr) && <Message error={error}>{error || validateErr}</Message>}
    </>
  );
};

Step2.propTypes = {
  pageContext: PropTypes.shape({
    kinds: PropTypes.objectOf(
      PropTypes.shape({
        memberships: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            priceRange: PropTypes.string.isRequired,
            currency: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
    benefits: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired).isRequired,
    payments: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
      }).isRequired
    ).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      [MEMBERSHIP]: PropTypes.string.isRequired,
      [PUBLISH]: PropTypes.bool.isRequired,
      [PAYMENT]: PropTypes.shape({
        [AMOUNT]: PropTypes.number.isRequired,
        [CURRENCY]: PropTypes.string.isRequired,
        [PAYMENT_METHOD]: PropTypes.string.isRequired,
      }).isRequired,
      [KIND]: PropTypes.string.isRequired,
      [COMPANY]: PropTypes.string,
      [FIRST_NAME]: PropTypes.string.isRequired,
      [LAST_NAME]: PropTypes.string.isRequired,
      [ADDRESS]: PropTypes.string.isRequired,
      [ZIP_CODE]: PropTypes.string.isRequired,
      [CITY]: PropTypes.string.isRequired,
      [EMAIL]: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Step2;
export { default as Head } from '@/components/Head';
