import { object, string, number } from 'yup';

import {
  COMPANY,
  FIRST_NAME,
  LAST_NAME,
  ADDRESS,
  ZIP_CODE,
  CITY,
  EMAIL,
  MEMBERSHIP,
  KIND,
  PAYMENT,
  AMOUNT,
  CURRENCY,
  PAYMENT_METHOD,
} from '@/server-api/consts';

export const validationSchema = object().shape({
  [KIND]: string().required('Ungültig'),
  [COMPANY]: string().when(KIND, {
    is: (kind) => kind === 'private',
    then: string().notRequired(),
    otherwise: string().required('Ungültig'),
  }),
  [FIRST_NAME]: string().required('Ungültig'),
  [LAST_NAME]: string().required('Ungültig'),
  [ADDRESS]: string().required('Ungültig'),
  [ZIP_CODE]: string().required('Ungültig'),
  [CITY]: string().required('Ungültig'),
  [EMAIL]: string().email('Ungültig').required('Ungültig'),
  [MEMBERSHIP]: string().required('Ungültig'),
  [PAYMENT]: object().shape({
    [AMOUNT]: number().required('Ungültig'),
    [CURRENCY]: string().required('Ungültig'),
    [PAYMENT_METHOD]: string().required('Ungültig'),
  }),
});
