/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { StyledSelect, StyledOption } from './styles';

const Select = ({ value, values, ...rest }) => (
  <StyledSelect value={value} {...rest}>
    {values.map(({ id, label }) => (
      <StyledOption key={id} value={id} label={label} />
    ))}
  </StyledSelect>
);

Select.defaultProps = {
  value: null,
};

Select.propTypes = {
  value: PropTypes.number,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Select;
